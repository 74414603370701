<template>
  <div class="express-order-edit">
    <el-row>
      <el-col :span="12">
        <div class="znl-component-input znl-border-input left">
          <label class="znl-input-title col-title-1">寄件方式：</label>
          <div class="znl-input-type col-content">
            <el-radio-group v-model="editData.EOrderType" size="mini">
              <el-radio label="c">C端寄件</el-radio>
              <el-radio label="eprint">云打印</el-radio>
            </el-radio-group>

            <el-popover placement="bottom-start" width="420" trigger="hover" :close-delay="600">
              <i class="el-icon-info" style="color:gray" slot="reference"></i>
              <div>
                <strong>C端寄件</strong>：在订单下单成功后，系统会将订单推送到对应的快递公司。对应的快递公司将会分配快递员上门取件，快递费用需要您与快递员或者快递公司进行结算
                <br />
                <strong>云打印</strong>：需要提前设置月结账号，系统通过月结账号，向对应的快递公司发送下单请求，并且可以直接打印出电子面单。
              </div>
            </el-popover>
          </div>
        </div>
      </el-col>
      <el-col :span="8">
        <a target="_blank" class="link" @click="event => {onSetMonthlyAcct()}">设置月结账号</a>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="8">
        <!-- C端寄件的快递公司 -->
        <znl-input
          v-if="editData.EOrderType === 'c'"
          title-width="70px"
          form-type="select"
          :select-options="kuaidiOptions"
          size="mini"
          layout="left"
          :border="true"
          :is-must-fill="true"
          title="快  递："
          width="200px"
          v-model="editData.Kuaidicom"
          type="text"
        ></znl-input>

        <!-- 云打印的快递公司 -->
        <znl-input
          v-if="editData.EOrderType === 'eprint'"
          title-width="70px"
          form-type="select"
          :select-options="kuaidiOptions2"
          size="mini"
          layout="left"
          :border="true"
          :is-must-fill="true"
          title="快  递："
          width="200px"
          v-model="editData.Kuaidicom"
          type="text"
          @change="(item) => {refreshTemplateData(item.key)}"
        ></znl-input>
      </el-col>
      <el-col :span="8">
        <div class="znl-component-input znl-border-input left" v-if="billNoTitle!==''">
          <label class="znl-input-title col-title-1 isMustFill">{{billNoTitle}}</label>
          <div class="znl-input-type col-content">{{editData.OrderNo}}</div>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="znl-component-input znl-border-input left ddd" v-if="editData.OrderFrom ==='taobao' && editData.EOrderType === 'eprint'">
          <label class="znl-input-title col-title-1">直接发货：</label>
          <div class="znl-input-type col-content">
            <el-checkbox v-model="editData.IsShipped"></el-checkbox>
            <el-popover placement="bottom-start" width="280" trigger="hover" :close-delay="600" style="margin-left:10px;">
              <i class="el-icon-info" style="color:gray" slot="reference"></i>
              <div>
                <!-- <strong>C端寄件</strong> -->
                直接将淘宝订单的状态修改为[已发货]
                <br />
              </div>
            </el-popover>
          </div>
        </div>
      </el-col>
    </el-row>

    <!-- 收件信息 -->
    <div class="receipt_content">
      <el-row>
        <el-col :span="8">
          <znl-input
            title-width="70px"
            form-type="input"
            size="mini"
            layout="left"
            :border="true"
            :is-must-fill="true"
            :clearable="true"
            title="收 件 人："
            width="200px"
            v-model="editData.RecManName"
            type="text"
          ></znl-input>
        </el-col>
        <el-col :span="8">
          <znl-input
            title-width="70px"
            form-type="input"
            size="mini"
            layout="left"
            :border="true"
            :clearable="true"
            :is-must-fill="true"
            title="手机号码："
            width="200px"
            v-model="editData.RecManMobile"
            type="text"
          ></znl-input>
        </el-col>

        <el-col :span="8">
          <znl-input
            title-width="70px"
            form-type="input"
            size="mini"
            layout="left"
            :border="true"
            :clearable="true"
            :is-must-fill="false"
            title="固定电话："
            width="200px"
            v-model="editData.RecManPhone"
            type="text"
          ></znl-input>
        </el-col>
      </el-row>

      <el-row>
        <div class="znl-component-input znl-border-input left">
          <label
            class="znl-input-title col-title-1"
          >地&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;址：</label>
          <div class="znl-input-type col-content">
            <v-distpicker
              :province="editData.RecManProvince"
              :city="editData.RecManCity"
              :area="editData.RecManArea"
              @province="item => {editData.RecManProvince = item.value}"
              @city="item => {editData.RecManCity = item.value}"
              @area="item => {editData.RecManArea = item.value}"
            ></v-distpicker>
          </div>
        </div>
      </el-row>
      <el-row>
        <el-col :span="24">
          <znl-input
            title-width="70px"
            form-type="input"
            size="mini"
            layout="left"
            :border="true"
            :clearable="true"
            :is-must-fill="true"
            title="详细地址："
            width="90%"
            v-model="editData.RecManAddr"
            type="text"
          ></znl-input>
        </el-col>
      </el-row>
    </div>

    <!-- 寄件信息 -->
    <el-row>
      <el-col :span="24">
        <div class="znl-component-input znl-border-input left">
          <label class="znl-input-title col-title-1 isMustFill">寄件信息：</label>
          <div class="znl-input-type col-content text-over">
            {{editData.SendManName}}
            <a @click="onEditSendAddr">(修改)</a>
            <span style="color: #a5a5a5; margin-left: 4px;">
              {{editData.SendManMobile || editData.SendManPhone}}
              {{editData.SendManProvince}}
              {{editData.SendManCity}}
              {{editData.SendManArea}}
              {{editData.SendManAddr}}
              {{editData.Postcode}}
            </span>
          </div>
        </div>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="8">
        <!-- C端寄件的付款方式 -->
        <znl-input
          v-if="editData.EOrderType==='c'"
          title-width="70px"
          form-type="select"
          :select-options="paymentTypeOptions"
          size="mini"
          layout="left"
          :border="true"
          title=" 支付方式："
          :clearable="true"
          width="200px"
          v-model="editData.PayType"
          type="text"
        ></znl-input>

        <!-- 云打印的付款方式 -->
        <znl-input
          v-if="editData.EOrderType==='eprint'"
          title-width="70px"
          form-type="select"
          :select-options="paymentTypeOptions2"
          size="mini"
          layout="left"
          :border="true"
          title="支付方式："
          :clearable="true"
          width="200px"
          v-model="editData.PayType"
          type="text"
        ></znl-input>
      </el-col>
      <el-col :span="8">
        <znl-input
          title-width="70px"
          form-type="select"
          :select-options="dayTypeOptions"
          v-show="editData.EOrderType === 'c'"
          size="mini"
          layout="left"
          :border="true"
          :clearable="true"
          title="预约日期："
          width="200px"
          v-model="editData.DayType"
          type="text"
        ></znl-input>
      </el-col>
      <el-col :span="8">
        <div class="znl-component-input znl-border-input left" style="text-align: right" v-show="editData.EOrderType === 'c'">
          <label class="znl-input-title col-title-1">时&nbsp;间：</label>
          <div class="col-content">
            <el-time-picker
              class="znl-time-picker"
              :disabled="(editData.DayType === null || editData.DayType === undefined || editData.DayType === '')"
              is-range
              v-model="editData.time"
              format="HH:mm"
            ></el-time-picker>
          </div>
        </div>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="8">
        <znl-input
          title-width="70px"
          form-type="input"
          size="mini"
          layout="left"
          :border="true"
          :clearable="true"
          placeholder="如: 电子产品"
          title="物品名称："
          width="200px"
          v-model="editData.Cargo"
          type="text"
        ></znl-input>
      </el-col>
      <el-col :span="16">
        <znl-input
          title-width="70px"
          form-type="input"
          size="mini"
          layout="left"
          :border="true"
          :clearable="true"
          title=" 备  注："
          width="454px"
          v-model="editData.Remark"
          type="text"
        ></znl-input>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="8">
        <znl-input
          title-width="70px"
          form-type="input"
          size="mini"
          layout="left"
          :border="true"
          :clearable="true"
          title="数 量(件)："
          :is-must-fill="editData.EOrderType==='eprint'"
          width="200px"
          v-model="editData.Count"
          type="text"
        ></znl-input>
      </el-col>
      <el-col :span="8">
        <znl-input
          title-width="70px"
          form-type="input"
          size="mini"
          layout="left"
          :border="true"
          :clearable="true"
          title="重 量(KG)："
          width="200px"
          :is-must-fill="editData.EOrderType==='eprint'"
          v-model="editData.Weight"
          type="text"
        ></znl-input>
      </el-col>
      <el-col :span="8">
        <znl-input
          title-width="70px"
          form-type="input"
          size="mini"
          layout="left"
          :border="true"
          :clearable="true"
          title="体 积(CM³)："
          :is-must-fill="editData.EOrderType==='eprint'"
          width="220px"
          v-model="editData.Volumn"
          type="text"
        ></znl-input>
      </el-col>
    </el-row>
    <el-row v-if="editData.EOrderType==='c'" style="height:28px;">
      <el-col :span="24"></el-col>
    </el-row>
    <el-row v-if="editData.EOrderType==='eprint'">
      <el-col :span="16">
        <znl-input
          title-width="70px"
          form-type="select"
          :select-options="templateOptions"
          size="mini"
          layout="left"
          :border="true"
          :clearable="true"
          title="打印模板："
          width="300px"
          v-model="editData.TemplateId"
          type="text"
        ></znl-input>
        <a target="_blank" class="link" @click="event => {onShowPrintTemplate()}">示例</a>
        <!-- <a target="_blank" class="link" @click="event => {saveSetup('templateid', editData.TemplateId)}">设为默认</a> -->
      </el-col>
      <el-col :span="8">
        <!-- <znl-input
          title-width="70px"
          form-type="input"
          size="mini"
          layout="left"
          :border="true"
          :clearable="true"
          title="打印高度："
          width="220px"
          v-model="editData.PrintHeight"
          type="text"
        ></znl-input>-->
      </el-col>
    </el-row>

    <el-row style="text-align:right; margin-top:8px;">
      <el-popover placement="top" width="420" trigger="hover" :close-delay="600" :visible-arrow="false">
        <a target="_blank" class="link mr5" @click="event => {saveSetup()}" slot="reference">设为默认</a>
        <div>
          设为默然后，
          <strong>快递、直接发货、支付方式、打印模板</strong>，
          在下次打开的时候将会默认为当前的值(<label style="color: #989898;font-size: 12px;">该设置只对当前登录账号有效</label>)
      </div>
      </el-popover>

      <znl-button
        style-type="main"
        @click="onSave()"
        :disabled="this.disableSave"
        class="save-btn"
        :height="30"
      >
        <i class="iconfont icon-save_btn_ic"></i>
        <span>保存</span>
      </znl-button>

      <znl-button
        style-type="main"
        @click="onSave('print')"
        :disabled="this.disableSave"
        class="save-btn"
        :height="30"
        v-if="editData.EOrderType === 'eprint'"
      >
        <i class="iconfont icon-save_btn_ic"></i>
        <span>保存并打印</span>
      </znl-button>

      <znl-button
        style-type="mac"
        @click="onSave('preview')"
        :disabled="this.disableSave"
        class="save-btn"
        :height="30"
        v-if="editData.EOrderType === 'eprint'"
      >
        <i class="iconfont icon-save_btn_ic"></i>
        <span>保存并预览</span>
      </znl-button>

      <!-- <znl-button
        style-type="mac"
        @click="onClose"
        :disabled="this.disableSave"
        class="save-btn"
        :height="30"
        v-if="showCloseBtn"
      >
        <i class="iconfont icon-close_btn"></i>
        <span>关闭</span>
      </znl-button>-->
    </el-row>

    <address-edit
      :visible="address.showEdit"
      v-if="address.showEdit"
      :addr-id="address.addressId"
      @close="data => onEditSendAddrClose(data)"
    ></address-edit>

    <!-- 月结账号设置 -->
    <monthly-account
      v-if="showMonthlyAccount"
      :visible="showMonthlyAccount"
      @close="() => {showMonthlyAccount = false}"
    ></monthly-account>

    <!-- 打印模板示例 -->
    <print-template
      v-if="showPrintTemplate"
      :visible="showPrintTemplate"
      @close="() => {this.showPrintTemplate = false;}"></print-template>
  </div>
</template>

<script>
import VDistpicker from "v-distpicker";
import addressEdit from "@c_modules/Logistics/AddressEdit";
import { isClient } from "~/lib/runtime";
import MonthlyAccount from "@c_modules/Logistics/MonthlyAccount";
import PrintTemplate from "@c_modules/Logistics/PrintTemplate"
import userSettings from "~assets/scripts/mixins/user_settings";

const CONFIG = {
  saveUrl: "EOrderExpress/AddOrder",
  getDefaultAddrUrl: "EDAddress/GetDefault",
  getTemplatesUrl: "EOrderTemplate/GetTemplates",

  userSetting: {
    code: 'ExpressOrder',
    name: '电子面单默认值'
  }
};
export default {
  name: "EExpressOrderEdit",
  mixins: [userSettings],
  components: {
    VDistpicker,
    addressEdit,
    MonthlyAccount,
    PrintTemplate
  },

  data() {
    return {
      kuaidiOptions: [
        { key: "shunfeng", value: "顺丰速运" },
        { key: "debangkuaidi", value: "德邦快递" },
        { key: "jd", value: "京东物流" }
      ],
      kuaidiOptions2: [
        { key: "shunfeng", value: "顺丰速运" },
        // { key:"ane66", value:"安能快递"},
        // { key:"debangkuaidi", value:"德邦快递"},
        { key: "ems", value: "EMS" },
        // { key:"guotongkuaidi", value:"国通快递"},
        { key: "huitongkuaidi", value: "百世快递" },
        // { key:"jd", value:"京东物流"},
        // { key:"kuayue", value:"跨越速运"},
        // { key:"pjbest", value:"品骏快递"},
        { key: "shentong", value: "申通快递" },
        // { key:"suer", value:"速尔快递"},
        // { key:"xinfengwuliu", value:"信丰物流"},
        // { key:"youshuwuliu", value:"优速物流"},
        // { key:"youzhengguonei", value:"邮政快递包裹"},
        { key: "yuantong", value: "圆通速递" },
        // { key:"yuantongguoji", value:"圆通国际"},
        { key: "yunda", value: "韵达快递" },
        { key: "zhaijisong", value: "宅急送" },
        { key: "zhongtong", value: "中通快递" }
        // { key:"ewe", value:"EWE全球快递"},
        // { key:"quanyikuaidi", value:"全一快递"},
        // { key:"tiantian", value:"天天快递"}
      ],
      kuaidiType: [],
      paymentTypeOptions: [
        { key: "SHIPPER", value: "寄付" },
        { key: "CONSIGNEE", value: "到付" }
      ],
      paymentTypeOptions2: [
        { key: "SHIPPER", value: "寄付" },
        { key: "CONSIGNEE", value: "到付" },
        { key: "MONTHLY", value: "月结" },
        { key: "THIRDPARTY", value: "第三方支付" }
      ],
      dayTypeOptions: [
        { key: "今天", value: "今天" },
        { key: "明天", value: "明天" },
        { key: "后天", value: "后天" }
      ],
      templateList: [],
      templateOptions: [],

      editData: {
        EOrderID: "",
        EOrderType: "c",
        OrderFrom: "",
        OrderGuid: "",
        OrderNo: "",
        Kuaidicom: "",
        RecManName: "",
        RecManMobile: "",
        RecManPhone: "",
        RecManAddr: "",
        RecManProvince: "",
        RecManCity: "",
        RecManArea: "",
        SendManName: "",
        SendManMobile: "",
        SendManPhone: "",
        SendManProvince: "",
        SendManCity: "",
        SendManArea: "",
        SendManAddr: "",
        Cargo: "",
        Count: 1,
        Weight: 1,
        Volumn: 750,
        Remark: "",
        PayType: "",
        DayType: "",
        PickupStartTime: "",
        PickupEndTime: "",
        ExpressOrderNo: "",
        ReturnTaskid: null,
        ReturnOrderId: null,
        IsCancel: false,
        CancelReason: null,
        TemplateId: null,
        PrintWidth: null,
        PrintHeight: null,
        IsShipped: true
      },

      address: {
        addressId: 0,
        showEdit: false
      },

      disableSave: false,
      billNoTitle: "",
      bEndMustFill: false,
      showMonthlyAccount: false,
      showPrintTemplate: false,

      defValues: {
        eOrderType: null,
        kuaidicom: null,
        paytype: null,
        templateId: null
      }
    };
  },
  props: {
    orderGuid: {
      type: String,
      default: ""
    },
    orderNo: {
      type: String,
      default: ""
    },
    eOrderType: {
      type: String,
      default: "c"
    },
    orderFrom: {
      type: String,
      default: ""
    },
    showCloseBtn: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    async onInit() {
      await this.loadData();
      await this.getDefaultSendAddr();
      await this.getTemplates();
      await this.loadSetup();
    },

    loadData() {
      this.editData.OrderFrom = this.orderFrom;
      this.editData.EOrderType = this.eOrderType;
      this.disableSave = false;
      if (this.orderFrom === "taobao") {
        this.loadDataFromTaobao();
        this.billNoTitle = "淘宝单号：";
      } else if (this.orderFrom === "stkout") {
        this.billNoTitle = "发货单号：";
        this.loadDataFromStkout();
      } else {
        this.editData.OrderNo = this.orderNo;
        this.editData.OrderGuid = this.orderGuid;
      }
    },

    loadDataFromTaobao() {
      if (this.$store.state.paramOrder) {
        let item = this.$store.state.paramOrder;
        this.editData.OrderNo = item.TidStr;
        this.editData.RecManName = item.ReceiverName;
        this.editData.RecManMobile = item.ReceiverMobile;
        this.editData.RecManPhone = item.ReceiverPhone;
        this.editData.RecManAddr = item.ReceiverAddress;
        this.editData.RecManProvince = item.ReceiverState;
        this.editData.RecManCity = item.ReceiverCity;
        this.editData.RecManArea = item.ReceiverDistrict;
        this.editData.SendManName = item.StoreName;
      }
      this.$store.commit("setParamOrder", {});
    },

    loadDataFromStkout() {
      let item = this.$store.state.paramOrder;
      this.editData.OrderGuid = item.StkOutGUID;
      this.editData.OrderNo = item.BillNo;
      this.editData.RecManName = item.Contact;
      this.editData.RecManMobile = item.Mobile;
      this.editData.RecManPhone = item.Telephone;
      this.editData.RecManAddr = item.Address;
      // this.editData.RecManProvince = item.ReceiverState;
      // this.editData.RecManCity = item.ReceiverCity;
      // this.editData.RecManArea = item.ReceiverDistrict;
      // this.editData.SendManName = item.StoreName;
      this.$store.commit("setParamOrder", {});
    },

    getDefaultSendAddr() {
      let param = { AddrType: "send" };
      this.$post(CONFIG.getDefaultAddrUrl, param, addr => {
        if (addr && addr.ID > 0) {
          _.extend(this.address, addr);
          this.address.addressId = addr.ID;
          this.editData.SendManName = addr.Name;
          this.editData.SendManMobile = addr.Mobile;
          this.editData.SendManPhone = addr.Phone;
          this.editData.SendManProvince = addr.Province;
          this.editData.SendManCity = addr.City;
          this.editData.SendManArea = addr.Area;
          this.editData.SendManAddr = addr.Addr;
        }
      });
    },

    // 加载打印模板
    async getTemplates() {
      await this.$post(CONFIG.getTemplatesUrl, {}, data => {
        if (data && data.length > 0) {
          this.templateList = data;
          this.refreshTemplateData(this.editData.Kuaidicom, this.editData.TemplateId);
        } else {
          this.showError("获取模板失败");
        }
      }).finally(() => {});
    },

    // 刷新打印模板下拉框
    refreshTemplateData(com = null, templateId = null) {
      com = com || this.editData.Kuaidicom;
      this.templateOptions = _.map(
        _.filter(this.templateList, m => m.Com === com),
        a => {
          return {
            key: a.Code,
            value: a.Name
          };
        }
      );
      templateId && (this.editData.TemplateId = templateId);

      if (!_.some(this.templateOptions, m => m.key === this.editData.TemplateId)) {
        this.editData.TemplateId = ''
      }
    },

    onEditSendAddr() {
      this.$store.commit("setParamOrder", { sendAddr: this.address });
      this.address.showEdit = true;
    },

    onEditSendAddrClose(addr = null) {
      this.address.showEdit = false;

      if (addr) {
        this.address.addressId = addr.ID;
        this.editData.SendManName = addr.Name;
        this.editData.SendManMobile = addr.Mobile;
        this.editData.SendManPhone = addr.Phone;
        this.editData.SendManProvince = addr.Province;
        this.editData.SendManCity = addr.City;
        this.editData.SendManArea = addr.Area;
        this.editData.SendManAddr = addr.Addr;
      }
    },

    // 保存前数据校验
    checkData() {
      let checkRule = [
        { key: "Kuaidicom", msg: "请选择快递" },
        { key: "OrderFrom", msg: "来源不能为空" },
        { key: "RecManName", msg: "请填写收件人" },
        // {key: 'RecManProvince', msg: '请选择收件人省份'},
        // {key: 'RecManCity', msg: '请选择收件人城市'},
        // {key: 'RecManArea', msg: '请选择收件人区/县'},
        { key: "RecManAddr", msg: "请填写收件人详细地址" },
        { key: "SendManName", msg: "请填写发件人" },
        { key: "SendManMobile", msg: "请填写发件人电话/手机" },
        // { key: "SendManProvince", msg: "请选择发件人省份" },
        // { key: "SendManCity", msg: "请选择发件人城市" },
        // { key: "SendManArea", msg: "请选择发件人区/县" },
        { key: "SendManAddr", msg: "请填写发件人详细地址" }
      ];
      let isPass = this.dataValid(this.editData, checkRule);
      if (!isPass) {
        return false;
      }
      if (
        !this.hasValue(this.editData.RecManMobile) &&
        !this.hasValue(this.editData.RecManPhone)
      ) {
        this.showError("收件人手机、电话至少填写一个");
        return false;
      }

      if (this.hasValue(this.editData.DayType)) {
        if (
          this.editData.time === null ||
          this.editData.time === "" ||
          this.editData.time === undefined ||
          this.editData.time.length === 0
        ) {
          this.showError("当选择了预约日期，必须输入预约时间");
          return false;
        }
        this.editData.PickupStartTime = this.editData.time[0];
        this.editData.PickupEndTime = this.editData.time[1];
      }
      if (this.editData.EOrderType === "eprint") {
        checkRule = [
          { key: "Count", msg: "请输入物品数量" },
          { key: "Weight", msg: "请输入物品重量" },
          { key: "Volumn", msg: "请输入物品体积" }
        ];
        return this.dataValid(this.editData, checkRule);
      }
      return true;
    },

    onSave(action = "") {
      // check data
      let ispass = this.checkData();
      if (!ispass) {
        return false;
      }

      this.disableSave = true;
      // 如果没有勾选模板，则选一个默认模板
      if (!this.hasValue(this.editData.TemplateId)) {
        _.some(this.templateList, item => {
          if (item.Com === this.editData.Kuaidicom && item.Deafult) {
            this.editData.TemplateId = item.Code;
            return true;
          }
        });
      }

      // 保存
      this.$post(CONFIG.saveUrl, this.editData, (data, logic) => {
        if (logic.code === 200) {
          this.showSuccess("添加电子面单成功");
          if (action === "print") {
            this.printBase64Image(data.ImgBase64);
          } else if (action === "preview") {
            this.printPreviewBase64Image(data.ImgBase64);
          }
          this.$emit("close", true);
        } else {
          let msg = logic.msg || "添加失败";
          this.showError(msg);
          this.disableSave = false;
        }
      });
    },

    onPrint () {},

    onClose () {
      if (isClient()) {
        this.expressDeliveryClose();
      } else {
        this.$emit("close");
      }
    },
    onSetMonthlyAcct() {
      this.showMonthlyAccount = true;
    },

    onShowPrintTemplate () {
      this.showPrintTemplate = true
      //打印模板
      let param = {
        eOrderType: this.editData.EOrderType,
        kuaidicom: this.editData.Kuaidicom, // 选中的快递公司
        templateId: this.editData.TemplateId, // 选中的模板
        allTemplates: this.templateList, // 模板数据源
        kuaidiOptions: this.kuaidiOptions2 }; // 快递公司下拉框数据源
      this.$store.commit("setParamOrder", param);
    },

    async loadSetup () {
      let items = await this.getUserSetup(CONFIG.userSetting.code);
      _.extend(this.defValues, items);

      // 寄件方式
      if (items.EOrderType) {
        this.editData.EOrderType = items.EOrderType
      }
      // 快递公司，打印模板
      if (items.Kuaidicom) {
        this.editData.Kuaidicom = items.Kuaidicom
        this.refreshTemplateData(this.editData.Kuaidicom, items.TemplateId)
      }
      // 付款方式
      items.PayType && (this.editData.PayType = items.PayType)
      //是否直接发货

      if (items.IsShipped !== null && items.IsShipped !== undefined) {
        this.editData.IsShipped = (items.IsShipped === "true")
      }
    },

    // 保存默认值
    saveSetup () {
      let items = {
        EOrderType: this.editData.EOrderType,
        Kuaidicom: this.editData.Kuaidicom,
        PayType: this.editData.PayType,
        TemplateId: this.editData.TemplateId,
        IsShipped: this.editData.IsShipped
      }

      this.saveUserSetup(CONFIG.userSetting.code, items, CONFIG.userSetting.name)
    }
  },

  async mounted() {
    await this.onInit();
  }
};
</script>
<style lang="scss">
.express-order-edit {
  .link {
    font-size: 12px;
    text-decoration: underline;
    margin-left: 5px;
    color: #2855f8;
  }
  .el-row {
    margin: 0px 4px 4px 4px;
  }
  .el-radio {
    margin-right: 15px !important;
  }
  .el-radio__label {
    font-size: 12px;
  }
  .col-title-1 {
    width: 70px;
  }
  .col-content {
    height: 28px;
    // width: auto;
    line-height: 30px;
    margin-left: 70px;
  }
  .receipt_content {
    background: #f6f6f6;
    margin-right: 5px;
    padding: 2px 0px 2px 0px;
  }
  .znl-input-title.isMustFill::before {
    content: "*";
    color: #e70;
    padding-right: 1px;
  }

  .znl-time-picker {
    width: 150px;
    height: 24px;
    font-size: 12px;
    border: 1px solid #ccc;
    border-radius: 2px;
    > div {
      line-height: 0;
    }
    .znl-component-input .el-input__inner {
      line-height: 24px;
    }
    .el-range-separator {
      width: 16px;
      font-size: 12px;
    }
    .el-range-input {
      width: 36px;
    }
  }

  .text-over {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .save-btn {
    margin-right: 15px;
  }
}

.distpicker-address-wrapper select {
  padding: 2px !important;
  height: 24px !important;
  font-size: 12px !important;
  line-height: 1.25 !important;
  border-color: #b3cfeb !important;
  border-radius: 2px !important;
}
</style>
