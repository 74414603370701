<template>
  <!-- 寄件地址弹窗 -->
  <div>
    <znl-dialog
      title="寄件信息"
      :visible="visible"
      subhead
      width="550px"
      height="250px"
      class="dialog-send-address-edit-box"
      :close-on-click-modal="false"
      :is-footer-show="true"
      v-loading="dialogLoading"
      element-loading-text="正在操作，请稍后..."
      @close="onClose"
    >
      <el-row>
        <znl-input
          title-width="70px"
          form-type="input"
          size="mini"
          layout="left"
          :border="true"
          :is-must-fill="true"
          title="寄 件 人："
          width="200px"
          v-model="editData.Name"
          type="text"
        ></znl-input>
      </el-row>

      <el-row>
        <znl-input
          title-width="70px"
          form-type="input"
          size="mini"
          layout="left"
          :border="true"
          :is-must-fill="true"
          title="手机号码："
          width="200px"
          v-model="editData.Mobile"
          type="text"
        ></znl-input>
      </el-row>

      <el-row>
        <znl-input
          title-width="70px"
          form-type="input"
          size="mini"
          layout="left"
          :border="true"
          :is-must-fill="false"
          title="固定电话："
          width="200px"
          v-model="editData.Phone"
          type="text"
        ></znl-input>
      </el-row>

      <el-row>
        <div class="znl-component-input znl-border-input left">
          <label
            class="znl-input-title col-title-1 isMustFill"
          >地&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;址：</label>
          <div class="znl-input-type col-content">
            <v-distpicker
              :province="editData.Province"
              :city="editData.City"
              :area="editData.Area"
              @province="item => {editData.Province = item.value}"
              @city="item => {editData.City = item.value}"
              @area="item => {editData.Area = item.value}"
            ></v-distpicker>
          </div>
        </div>
      </el-row>
      <el-row>
        <el-col :span="24">
          <znl-input
            title-width="70px"
            form-type="input"
            size="mini"
            layout="left"
            :border="true"
            :is-must-fill="true"
            title="详细地址："
            width="90%"
            v-model="editData.Addr"
            type="text"
          ></znl-input>
        </el-col>
      </el-row>

      <el-row slot="footer">
        <znl-button
          style-type="main"
          @click="onSave"
          :disabled="this.disableSave"
          class="save-btn"
          :height="30"
        >
          <i class="iconfont icon-save_btn_ic"></i>
          <span>确认</span>
        </znl-button>
        <znl-button
          style-type="mac"
          @click="onClose()"
          :disabled="this.disableSave"
          class="save-btn"
          :height="30"
        >
          <i class="iconfont icon-close_btn"></i>
          <span>关闭</span>
        </znl-button>
      </el-row>
    </znl-dialog>
  </div>
</template>
<script>
import VDistpicker from "v-distpicker";
import ExpressOrderEdit from "@c_modules/Logistics/EExpressOrderEdit";

const CONFIG = {
  saveUrl: "EDAddress/Save"
};

export default {
  name: "AddressEditDialog",
  mixins: [],
  components: {
    VDistpicker,
    ExpressOrderEdit
  },

  data() {
    return {
      dialogLoading: false,

      disableSave: false,
      editData: {
        ID: 0,
        AddrType: "send",
        Name: "",
        Mobile: "",
        Phone: "",
        Province: "",
        City: "",
        Area: "",
        Addr: "",
        Postcode: ""
      }
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    addrId: {
      type: Number,
      default: 0
    }
  },

  methods: {
    onInit() {
      if (this.$store.state.paramOrder.sendAddr) {
        let data = this.$store.state.paramOrder.sendAddr;
        this.editData = _.extend(this.editData, data);
        this.$store.commit("setParamOrder", {});
      }
    },
    onSave() {
      let checkRule = [
        { key: "Name", msg: "姓名不能为空" },
        // {key: 'Mobile', msg: '联系电话/手机不能为空'},
        { key: "Province", msg: "省份不能为空" },
        { key: "City", msg: "城市不能为空" },
        { key: "Area", msg: "区/县不能为空" },
        { key: "Addr", msg: "详细地址不能为空" }
      ];
      var isPass = this.dataValid(this.editData, checkRule);
      if (!isPass) {
        return false;
      }
      if (
        !this.hasValue(this.editData.Mobile) &&
        !this.hasValue(this.editData.Mobile)
      ) {
        this.showError("联系人电话、手机至少填写一个");
        return false;
      }
      this.disableSave = true;
      this.$post(CONFIG.saveUrl, this.editData, (data, logic) => {
        this.disableSave = false;
        if (logic.code === 200) {
          this.showSuccess("保存成功");
          this.onClose(this.editData);
        } else {
          let msg = logic.msg || "保存失败";
          this.showError(msg);
        }
      });
    },
    onClose(data = null) {
      this.$emit("close", data);
    }
  },
  mounted() {
    this.onInit();
  }
};
</script>
