<template>
  <!-- 月结账号编辑 -->
  <div>
    <znl-dialog
      title="月结账号编辑"
      :visible="visible"
      subhead
      width="350px"
      height="230px"
      class="dialog-monthly-account-edit-box"
      :close-on-click-modal="false"
      :is-footer-show="true"
      v-loading="dialogLoading"
      element-loading-text="正在操作，请稍后..."
      @close="onClose"
    >
      <el-row>
        <znl-input
          title-width="90px"
          form-type="select"
          :select-options="companyOption"
          size="mini"
          layout="left"
          :border="true"
          :is-must-fill="true"
          title="快递公司："
          width="250"
          v-model="editData.Code"
          @change="onCompanyChange"
          type="text"
        ></znl-input>
      </el-row>

      <el-row>
        <znl-input
          title-width="90px"
          form-type="input"
          size="mini"
          layout="left"
          :border="true"
          :is-must-fill="isPartnerIdMust"
          title="PartnerId："
          width="95%"
          v-model="editData.PartnerId"
          type="text"
        ></znl-input>
      </el-row>
      <el-row>
        <znl-input
          title-width="90px"
          form-type="input"
          size="mini"
          layout="left"
          :border="true"
          :is-must-fill="isPartnerKeyMust"
          title="PartnerKey："
          width="95%"
          v-model="editData.PartnerKey"
          type="text"
        ></znl-input>
      </el-row>
      <el-row>
        <znl-input
          title-width="90px"
          form-type="input"
          size="mini"
          layout="left"
          :border="true"
          :is-must-fill="isNetMust"
          title="收件网点："
          width="95%"
          v-model="editData.Net"
          type="text"
        ></znl-input>
      </el-row>

      <el-row slot="footer">
        <znl-button style-type="main" @click="onSave" :disabled="this.disableSave" class="save-btn" :height="30">
          <i class="iconfont icon-save_btn_ic"></i>
          <span>确认</span>
        </znl-button>
        <znl-button style-type="mac" @click="onClose()" :disabled="this.disableSave" class="save-btn" :height="30">
          <i class="iconfont icon-close_btn"></i>
          <span>关闭</span>
        </znl-button>
      </el-row>

    </znl-dialog>
  </div>
</template>
<script>

const CONFIG = {
  saveUrl: 'EOrderSetting/SaveMonthlyAccount',
  deleteUrl: 'EOrderSetting/DeleteMonthlyAccount'
}

export default {
  name: 'MonthlyAccountEdit',
  mixins: [],
  components: {
  },

  data () {
    return {
      dialogLoading: false,
      disableSave: false,
      companyOption: [
        { key:"ane66", value:"安能快递", partnerId: true, partnerKey :false, net :false},
        { key:"debangkuaidi", value:"德邦快递", partnerId: true, partnerKey :false, net :false},
        { key:"ems", value:"EMS", partnerId: true, partnerKey: true, net :false},
        { key:"guotongkuaidi", value:"国通快递", partnerId: true, partnerKey: true, net: true},
        { key:"huitongkuaidi", value:"百世快递", partnerId: true, partnerKey: true, net :false},
        { key:"jd", value:"京东物流", partnerId: true, partnerKey :false, net :false},
        { key:"kuayue", value:"跨越速运", partnerId: true, partnerKey: true, net :false},
        { key:"pjbest", value:"品骏快递", partnerId: true, partnerKey: true, net :false},
        { key:"shentong", value:"申通快递", partnerId: true, partnerKey: true, net: true},
        { key:"shunfeng", value:"顺丰速运", partnerId: true, partnerKey :false, net :false},
        { key:"suer", value:"速尔快递", partnerId: true, partnerKey :false, net: true},
        { key:"xinfengwuliu", value:"信丰物流", partnerId: true, partnerKey: true, net :false},
        { key:"youshuwuliu", value:"优速物流", partnerId: true, partnerKey: true, net :false},
        { key:"youzhengguonei", value:"邮政快递包裹", partnerId: true, partnerKey: true, net :false},
        { key:"yuantong", value:"圆通速递", partnerId: true, partnerKey: true, net :false},
        { key:"yuantongguoji", value:"圆通国际", partnerId: true, partnerKey: true, net :false},
        { key:"yunda", value:"韵达快递", partnerId: true, partnerKey: true, net :false},
        // { key:"zhaijisong", value:"宅急送", partnerId :false, partnerKey :false, net :false},
        { key:"zhongtong", value:"中通快递", partnerId: true, partnerKey: true, net :false},
        { key:"ewe", value:"EWE全球快递", partnerId: true, partnerKey: true, net :false},
        { key:"quanyikuaidi", value:"全一快递", partnerId: true, partnerKey :false, net :false},
        { key:"tiantian", value:"天天快递", partnerId: true, partnerKey: true, net: true}
      ],
      editData: {
        Code: '',
        Name: '',
        PartnerId: '',
        PartnerKey: '',
        Net: ''
      }
    }
  },
  computed: {
    isPartnerIdMust () {
      let comp = _.find(this.companyOption, item => item.key === this.editData.Code)
      return (comp && comp.partnerId)
    },

    isPartnerKeyMust () {
      let comp = _.find(this.companyOption, item => item.key === this.editData.Code)
      return (comp && comp.partnerKey)
    },

    isNetMust () {
      let comp = _.find(this.companyOption, item => item.key === this.editData.Code)
      return (comp && comp.net)
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    onInit () {
    },

    onCompanyChange () {},

    onSave () {
      if (!this.hasValue(this.editData.Code)) {
        this.showError('请选择快递公司')
        return false
      }
      let comp = _.find(this.companyOption, item => {
        return item.key === this.editData.Code
      })
      if (comp) {
        let name = comp.value
        if (comp.partnerId && !this.hasValue(this.editData['PartnerId'])) {
          this.showError(`请输入[${name}]的PartnerId`)
          return false
        }
        if (comp.partnerKey && !this.hasValue(this.editData['PartnerKey'])) {
          this.showError(`请输入[${name}]的PartnerKey`)
          return false
        }
        if (comp.net && !this.hasValue(this.editData['Net'])) {
          this.showError(`请输入[${name}]的收件网点名称`)
          return false
        }
      }

      this.disableSave = true
      this.$post(CONFIG.saveUrl, this.editData, (data, logic) => {
        this.disableSave = false
        if (logic.code === 200) {
          this.showSuccess('保存成功')
          this.onClose(this.editData)
        } else {
          let msg = logic.msg || '保存失败'
          this.showError(msg)
        }
      })
    },
    onClose (data = null) {
      this.$emit("close", data)
    }
  },
  mounted () {
    this.onInit()
  }
}
</script>
<style lang="scss">
.dialog-monthly-account-edit-box{
  label.isMustFill::before{
    content: '*';
    color: #E70;
    font-weight: 700 !important;
  }
}
</style>
