<template>
  <!-- 嵌套在winform的电子面单编辑页面 -->
  <div style="padding-left: 5px !important;" class="znl-dialog__body express-order-from-win-view">
    <div>
      <express-order-edit
        ref="orderModule"
        :order-guid="orderGuid"
        :order-no="orderNo"
        :e-order-type="eOrderType"
        :order-from="orderFrom"
        :show-close-btn="false"
      ></express-order-edit>
    </div>
  </div>
</template>
<script>
import { on, off } from "~assets/scripts/utils/dom";
import ExpressOrderEdit from "@c_modules/Logistics/EExpressOrderEdit";

export default {
  name: "EExpressOrderView",
  components: {
    ExpressOrderEdit
  },
  data() {
    return {
      showEdit: false,
      orderGuid: "",
      orderNo: "",
      eOrderType: "c",
      orderFrom: "eprint",
      orderData: {}
    };
  },
  methods: {
    loadData() {
      console.log(this.orderFrom, "orderFrom");

      if (!this.hasValue(this.orderFrom)) {
        // this.showError('来源不能为空')
        this.unLoad();
      } else {
        this.showEdit = true;
        this.$refs.orderModule.loadData();
      }
      this.showEdit = true;
    },
    unLoad() {
      this.showEdit = false;
    },

    // 接收传递过来的参数
    onExpressOrder(e) {
      console.log(e, "onWinExpressOrder");
      let { orderFrom, order } = JSON.parse(e.data);
      this.orderFrom = orderFrom;
      this.orderNo = order.BillNo;
      this.orderGuid = order.StkOutGUID;
      this.orderData = order;
      this.eOrderType = "eprint";
      this.$store.commit("setParamOrder", order);
      this.$nextTick(() => {
        this.loadData();
      });
    }
  },
  watch: {
    $route(to, from) {
      // if (to.name === 'Logistics/EExpressOrderForWin') {
      //   this.loadData()
      // } else {
      //   this.unLoad()
      // }
    }
  },
  mounted() {
    on(window, "onWinExpressOrder", this.onExpressOrder);

    if (this.$route.name === "Logistics/EExpressOrderForWin") {
      this.loadData();
    } else {
      this.unLoad();
    }
  },

  beforeDestroy() {
    console.log("off on win express order");
    off(window, "onWinExpressOrder", this.onExpressOrder);
  }
};
</script>
<style lang="scss">
.express-order-from-win-view {
  width: 730px !important;
  min-width: 730px !important;
  height: 400px !important;
  background-color: #ffffff;
}
</style>
