
const CONFIG = {
  getUrl: "UserSetup/GetConfigs",
  saveUrl: 'UserSetup/SaveConfigs',
  deleteUrl: 'UserSetup/Delete'
}

export default {
  data () {
    return {
      lists: []
    }
  },
  methods: {
    async getALLUserSettings (isForce = false) {
      await this.$post(CONFIG.getUrl, {}, data => {

        this.lists = data
        this.$store.commit('setCommonDatas', {
          type: 'userSetupDatas',
          datas: data
        })
      })
    },

    async getUserSetup(module, key = null) {
      let datas = this.$store.state.commonDatas.userSetupDatas;
      if (datas === undefined || datas === null) {
        await this.getALLUserSettings();
      }

      datas = this.$store.state.commonDatas.userSetupDatas;

      if (datas) {
        if (key) {
          return datas[module][key]
        } else {
          return datas[module]
        }
      }

      return null
    },

    async saveUserSetup(module, items = null, moduleName = null) {

      if (!this.hasValue(module)) {
        this.$message({message: '模块不能为空', type: 'error'})
        return false;
      }
      if (items === null) {
        this.$message({message: '配置项不能为空', type: 'error'})
        return false;
      }
      let param = {
        Module: module,
        ModuleName: moduleName,
        Items: items
      }
      this.$post(CONFIG.saveUrl, param, (data, logic) => {
        if (logic.code === 200) {
          this.$message({message: '保存成功', type: 'success'})
        } else {
          let msg = logic.msg || "保存失败"
          this.$message({message: msg, type: 'error'})
        }
      })
    },

    hasValue (val) {
      return val !== null && val !== undefined && val !== '';
    }
  }
}
