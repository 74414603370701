<template>
  <znl-dialog
    :visible="visible"
    width="726px"
    height="386px"
    title="月结账号列表"
    :append-to-body="true"
    :close-on-click-modal="false"
    :is-footer-show="false"
    @close="()=>{$emit('close')}"
  >
    <div class="dialog-eorder-monly-account-taobao" style="height: 100%">
      <znl-table
        ref="table"
        gridtype="base"
        :show-check="false"
        :columns="columns"
        :item-source="itemSource"
        v-loading="loading"
        element-loading-text="数据加载中,请稍后..."
        box-class="dialog-eorder-monly-account-taobao"
      >
        <div slot="header" class="v-table-toolbar searchbox">
          <el-row>
            <znl-button style-type="mac" @click="onAddAcct()">
              <i class="iconfont icon-add_btn_ic"></i>
              <span>增加账号</span>
            </znl-button>
            <znl-button style-type="mac" @click="onDeleteAcct()">
              <i class="iconfont icon-delete_btn_ic"></i>
              <span>删除</span>
            </znl-button>
          </el-row>
        </div>
      </znl-table>
    </div>
    <monthly-account-edit
      v-if="showEditForm"
      :visible="showEditForm"
      @close="val => onEditClose(val)"
    ></monthly-account-edit>
  </znl-dialog>
</template>

<script>
import MonthlyAccountEdit from "@c_modules/Logistics/MonthlyAccountEdit";

const CONFIG = {
  searchUrl: "EOrderSetting/Search",
  deleteUrl: "EOrderSetting/DeleteMonthlyAccount"
};

const gridCols = [
  {
    CTitle: "公司编码",
    BindField: "Code",
    IsShow: true,
    ColumnWidth: 100,
    DataType: 1
  },
  {
    CTitle: "快递公司名称",
    BindField: "Name",
    IsShow: true,
    ColumnWidth: 100,
    DataType: 1
  },
  {
    CTitle: "PartnerId",
    BindField: "PartnerId",
    IsShow: true,
    ColumnWidth: 120,
    DataType: 1
  },
  {
    CTitle: "PartnerKey",
    BindField: "PartnerKey",
    IsShow: true,
    ColumnWidth: 160,
    DataType: 1
  },
  {
    CTitle: "收件网点名称",
    BindField: "Net",
    IsShow: true,
    ColumnWidth: 160,
    DataType: 1
  }
];

export default {
  name: "EOrder_MonthlyAccount",
  mixins: [],
  components: {
    MonthlyAccountEdit
  },
  data() {
    return {
      columns: [],
      itemSource: [],
      loading: false,
      showEditForm: false
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
      propsync: true
    },
    inquireGUIDS: {
      type: Array
    }
  },
  watch: {
    p_visible(val) {
      if (val) {
        this.onLoaded = true;
      }
    }
  },
  methods: {
    async onInit() {
      this.columns = gridCols;
      await this.onBindData();
      this.$nextTick(() => {
        this.$refs.table.init();
        this.$emit("page-loading", false);
      });
    },
    async onBindData() {
      this.loading = true;
      this.$post(CONFIG.searchUrl, {}, data => {
        console.log(data);

        this.itemSource = data;
        this.loading = false;
      }).finally(() => {
        this.loading = false;
      });
    },
    onAddAcct() {
      this.showEditForm = true;
    },
    async onDeleteAcct() {
      let row = this.$refs.table.getActiveRow();
      if (!this.hasValue(row.Code)) {
        return this.$message({
          message: "请选中要删除的月结账号",
          type: "warning"
        });
      } else {
        let info = { info: row };
        this.$store.commit("setParamOrder", info);
        this.$nextTick(function() {
          this.showEditBox = true;
        });
      }

      let isok = await this.$confirm(
        "删除月结账号可能会导致无法正常使用电子面单云打印，您确定要删除吗？",
        "提示",
        {
          confirmButtonText: "确定删除",
          cancelButtonText: "取消",
          type: "warning"
        }
      );
      if (isok) {
        this.$post(CONFIG.deleteUrl, { Code: row.Code }, (data, logic) => {
          if (logic.code === 200) {
            this.onBindData();
            this.showSuccess("删除成功");
          } else {
            let msg = logic.msg || "删除失败";
            this.showError(msg);
          }
        });
      }
    },
    onCancel() {
      this.$emit("close");
    },

    onEditClose(item = null) {
      if (item) {
        this.onBindData();
      }
      this.showEditForm = false;
    }
  },
  mounted() {
    this.onInit();
  }
};
</script>

<style lang="scss">
</style>
