<template>
  <!-- 电子面单弹窗 -->
  <div>
    <znl-dialog
      title="电子面单 - 模板示例"
      :visible="visible"
      subhead
      width="680px"
      height="400px"
      class="dialog-eorder-print-template"
      :close-on-click-modal="false"
      :is-footer-show="false"
      v-loading="dialogLoading"
      element-loading-text="正在操作，请稍后..."
      @close="close"
    >
      <el-row>
        <el-col :span="8">
          <el-row>
            <znl-input
              form-type="select"
              :select-options="kuaidiOptions"
              size="mini"
              layout="left"
              :border="true"
              :clearable="true"
              placeholder="请选择快递公司"
              width="120px"
              v-model="kuaidicom"
              @change="(item) => {refreshTemplateData(item.key)}"
              type="text"
            ></znl-input>
          </el-row>
          <el-row>
            <znl-input
              form-type="select"
              :select-options="templateOptions"
              size="mini"
              layout="left"
              :border="true"
              :clearable="true"
              placeholder="请选择电子面单模板"
              width="160px"
              v-model="templateId"
              @change="(item) => {bindImage(item.key)}"
              type="text"
            ></znl-input>
            <a :href="imgPath" target="_blank" style="margin-left:3px;" class="link">
              大图
            </a>
          </el-row>
          <el-row>
            <div style="font-size:12px; display:inline-block">面单尺寸：{{size.width}}*{{size.height}}</div>
          </el-row>
          <el-row>
            <znl-button
              style-type="mac"
              @click="close()"
              :height="30"
            >
              <span>关闭</span>
            </znl-button>
          </el-row>
        </el-col>
        <el-col :span="16" style="margin-top:-42px;">
          <el-row style="text-align:center; ">
            <img style="height:400px; border: 1px solid #ccc; color:#000" :src="imgPath" v-show="templateId !== ''" />
          </el-row>
        </el-col>
      </el-row>

    </znl-dialog>
  </div>
</template>
<script>

export default {
  name: "PrintTemplateDialog",
  mixins: [],
  components: {},

  data() {
    return {
      dialogLoading: false,
      kuaidicom: '',
      kuaidiOptions: [],
      allTemplates: [],
      templateOptions: [],
      templateId: '',
      imgPath: '',
      size: {
        width: 0,
        height: 0
      }
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    orderGuid: {
      type: String,
      default: ""
    }
  },

  methods: {
    onInit () {
      let param = this.$store.state.paramOrder;
      if (param) {
        this.kuaidicom = param.kuaidicom;
        this.templateId = param.templateId;
        this.kuaidiOptions = param.kuaidiOptions;
        this.allTemplates = param.allTemplates;
      }
      this.$store.commit("setParamOrder", {});
      this.refreshTemplateData()
      this.bindImage(this.templateId)
    },
    // 刷新打印模板下拉框
    refreshTemplateData(com = null) {
      com = com || this.kuaidicom;
      this.templateOptions = _.map(
        _.filter(this.allTemplates, m => m.Com === com),
        a => {
          return {
            key: a.Code,
            value: a.Name
          };
        }
      );
      if (!_.some(this.templateOptions, m => m.key === this.templateId)) {
        this.templateId = ''
      }
    },
    bindImage (templateId = null) {
      templateId = templateId || this.templateId;
      let item = this.findVal(this.allTemplates, m => m.Code === templateId);
      if (item) {
        this.size.width = item.Width;
        this.size.height = item.Height;
      } else {
        this.size.width = 0;
        this.size.height = 0;
      }
      // 这里直接拼接url。用templateId作为文件名， jpg作为扩展名。只需要将文件上传到指定位置即可
      this.imgPath = "https://bom-ai-read.oss-cn-shenzhen.aliyuncs.com/erp-static/e-express-order/print-template-demos/" + templateId + ".jpg";
    },
    onImgError () {
      this.imgPath = "https://bom-ai-read.oss-cn-shenzhen.aliyuncs.com/erp-static/e-express-order/print-template-demos/404.jpg";
    },
    close() {
      this.$emit("close");
    }
  },
  mounted () {
    this.onInit();
  }
};
</script>
<style lang="scss">
.dialog-eorder-print-template{
  .znl-dialog__headerbtn{
    z-index: 9999;
  }
}
</style>
